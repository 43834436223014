<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("user.profile.mail.title") }}
        </div>
      </CCol>
      <CCol sm="auto">
        <CButton color="primary" @click="onAddEmail()">
          AGGIUNGI INDIRIZZO EMAIL
        </CButton>
      </CCol>
    </CRow>

    <CCard>
      <CCardBody>
        <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
          {{ error.general().join(" ") }}
        </CAlert>

        <ejs-grid
          :dataSource="dm"
          :allowPaging="false"
          :allowSorting="true"
          ref="grid"
        >
          <e-columns>
            <e-column headerText="Email" field="email"></e-column>
            <e-column
              headerText="Verificato"
              field="verified"
              :template="verifiedTemplate"
            ></e-column>
            <e-column
              headerText="Principale"
              field="primary"
              :template="primaryTemplate"
            ></e-column>
            <e-column headerText="" :template="actionsTemplate"></e-column>
          </e-columns>
        </ejs-grid>
      </CCardBody>
    </CCard>

    <CModal :show="modal.show" :closeOnBackdrop="false" :centered="true">
      <template #header>
        <h5 class="modal-title">AGGIUNGI INDIRIZZO EMAIL</h5>
      </template>

      <CAlert v-if="modal.error.hasGeneralErrors()" color="danger" class="mb-3">
        {{ modal.error.general().join(" ") }}
      </CAlert>

      <form action="" @submit.prevent="addEmail">
        <CInput
          v-model="modal.form.email"
          label="Indirizzo email"
          type="email"
          :isValid="modal.error.isValidField('email')"
          :invalidFeedback="modal.error.fieldError('email')"
          required
        />
      </form>

      <template #footer>
        <CButton color="primary" variant="outline" @click.prevent="closeModal"
          >ANNULLA</CButton
        >
        <vue-ladda
          :loading="modal.loading"
          data-style="zoom-in"
          button-class="btn btn-primary px-4"
          @click.prevent="addEmail"
          >CONFERMA</vue-ladda
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { Page, Sort } from "@syncfusion/ej2-vue-grids";
import { GetDataManagerNew } from "../../../ds";
import TableActions from "./TableActions";
import TableBoolean from "./TableBoolean";
import EventBus from "../../../helpers/EventBus";
import errorResponse from "../../../helpers/error";

export default Vue.extend({
  name: "Email",

  data() {
    const dm = GetDataManagerNew("auth_email");

    return {
      dm: dm,
      error: errorResponse(),
      modal: {
        show: false,
        loading: false,
        error: errorResponse(),
        form: {
          email: "",
        },
      },

      verifiedTemplate: () => {
        return {
          template: {
            extends: TableBoolean,
            propsData: {
              field: "verified",
            },
          },
        };
      },

      primaryTemplate: () => {
        return {
          template: {
            extends: TableBoolean,
            propsData: {
              field: "primary",
            },
          },
        };
      },

      actionsTemplate: () => {
        return { template: TableActions };
      },
    };
  },
  provide: {
    grid: [Page, Sort],
  },

  mounted() {
    EventBus.$on("email:remove", this.onRemove);
    EventBus.$on("email:mark_as_primary", this.onMarkAsPrimary);
  },

  beforeDestroy() {
    EventBus.$off("email:remove", this.onRemove);
    EventBus.$off("email:mark_as_primary", this.onMarkAsPrimary);
  },

  methods: {
    onAddEmail() {
      this.modal.error.reset();
      this.modal.form.email = "";
      this.modal.show = true;
    },

    closeModal() {
      this.modal.show = false;
    },

    addEmail() {
      const requestParams = {
        email: this.modal.form.email,
      };
      this.modal.error.reset();
      this.dm
        .insert(requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Email aggiunta con successo",
            color: "success",
            autohide: true,
          });
          this.modal.show = false;
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.modal.error.set(body.errors);
        });
    },

    removeEmail(id) {
      this.error.reset();
      this.dm
        .remove("", id)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Email rimossa con successo",
            color: "success",
            autohide: true,
          });
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    markAdPrimaryEmail(id) {
      const requestParams = {
        id: id,
        primary: true,
      };
      this.error.reset();
      this.dm
        .update("", requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Email impostata come primaria rimossa con successo",
            color: "success",
            autohide: true,
          });
          this.error.reset();
          this.$refs.grid.refresh();
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },

    onRemove(item) {
      this.removeEmail(item.id);
    },

    onMarkAsPrimary(item) {
      this.markAdPrimaryEmail(item.id);
    },
  },
});
</script>
