<template>
  <div class="d-flex justify-content-end">
    <dropdown-menu :items="actions" :select="onActionSelect">
      <font-awesome-icon icon="ellipsis-h"
    /></dropdown-menu>
  </div>
</template>

<script>
import EventBus from "../../../helpers/EventBus";

const EMAIL_ACTIONS = [
  { id: "MARK_AS_PRIMARY", text: "Imposta come principale" },
  { id: "DELETE", text: "Rimuovi" },
];

export default {
  name: "TableActions",

  computed: {
    actions() {
      const isPrimary = this.data.primary === true;
      return EMAIL_ACTIONS.filter((a) => {
        return a.id === "DELETE" && isPrimary ? false : true;
      });
    },
  },
  methods: {
    onActionSelect: function (args) {
      const { id } = args.item;

      if (id === "DELETE") {
        if (
          confirm(
            `Confermi di voler rimoovere questo indirizzo email "${this.data.email}"?`
          ) == true
        ) {
          EventBus.$emit("email:remove", this.data);
        }
      }

      if (id === "MARK_AS_PRIMARY") {
        EventBus.$emit("email:mark_as_primary", this.data);
      }
    },
  },
};
</script>
